import React, {useState} from 'react';
import {Button, TextField, Stack, InputAdornment, IconButton} from "@mui/material";
import {Snackbar, Alert} from "@mui/material";
import { CircularProgress } from "@mui/material";
import {UpdateUserInfo} from "../../../api/routes/user";

export default function ParameterForm({id,initialData,onSuccess}){
    const [formData, setFormData] = useState({
        id: initialData.id,
        firstName: initialData.firstName,
        lastName: initialData.lastName,
        email: initialData.email,
        phoneNumber: initialData.phoneNumber,
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const[success, setSuccess] = useState(false);




    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('enterprise.')) {
            const fieldName = name.split('.')[1];
            setFormData({
                ...formData,
                enterprise: {
                    ...formData.enterprise,
                    [fieldName]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null)
        setSuccess(false);
        if(onSuccess) {
            onSuccess()
        }

        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const updatedUserData = {
                id: formData.id,
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                enterprise: formData.enterprise
            };

            const response = await UpdateUserInfo(id, updatedUserData);
            console.log(response);
            setLoading(false);
            setSuccess(true);
            if (onSuccess) {
                onSuccess();
            }


        }catch (error){
            setError(error)
            setLoading(false);
        }


    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        label="Prénom"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Nom"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Téléphone"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{position: 'relative'}}
                    >
                        {loading && <CircularProgress size={24} sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            mt: '-12px',
                            ml: '-12px'
                        }}/>}
                        Mettre à jour
                    </Button>
                </Stack>
            </form>


            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Information mise à jour avec succès
                </Alert>
            </Snackbar>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error?.message || "Un problème est survenu lors de la mise à jour des informations"}
                </Alert>
            </Snackbar>
        </>

    );


}