import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Stack, IconButton, InputAdornment, TextField, CircularProgress, Snackbar, Alert} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import {registerUser} from "../../../api/routes/register";

export default function RegisterForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const[success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        enterpriseName: ''
    })

    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordChecks, setPasswordChecks] = useState({
        validLength: false,
        number: false,
        uppercase: false,
        specialCharacter: false
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const hasValidLength = (password) => password.length >= 8;
    const hasNumber = (password) => /\d/.test(password);
    const hasUppercase = (password) => /[A-Z]/.test(password);
    const hasSpecialCharacter = (password) => /[@$!%*#?&]/.test(password);

    const validatePassword = (password) => {
        return hasValidLength(password) &&
            hasNumber(password) &&
            hasUppercase(password) &&
            hasSpecialCharacter(password);
    };

    const handleChange =(e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        if (e.target.name === "password") {
            setPasswordChecks({
                validLength: hasValidLength(e.target.value),
                number: hasNumber(e.target.value),
                uppercase: hasUppercase(e.target.value),
                specialCharacter: hasSpecialCharacter(e.target.value)
            });
            setPasswordValid(validatePassword(e.target.value));
        }
    }




    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true)
        setError(null);

        try{
            const response = await registerUser(formData);
            if (!passwordValid) {
                setError("Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one special character.");
                setLoading(false);
            }
            if (response.status === 201) {
                console.log('User created', response.data);
                // Show a success toast notification at the bottom right
                navigate('/verify', {replace: true});
            } else {
                console.log('Unexpected response', response);
                // Show an error toast notification at the bottom right
            }
            navigate('/verify', {replace: true});
        }catch(error){
            setError("Erreur lors de la creation de compte. Veuillez réessayer.")
        }finally {
            setLoading(false);
        }
    }




    return (

        <>
            <form onSubmit={handleSubmit}>
                <Stack  spacing={3}>
                    <TextField
                        required
                        name="firstName"
                        label="Entrez votre prénom"
                        value={formData.firstName}
                        onChange={handleChange}
                        aria-required={true}
                    />

                    <TextField
                        required
                        name="lastName"
                        label="Entrez votre nom"
                        value={formData.lastName}
                        onChange={handleChange}
                        aria-required={true}
                    />

                    <TextField
                        required
                        name="enterpriseName"
                        label="Nom de l'entreprise"
                        value={formData.enterpriseName}
                        onChange={handleChange}
                        aria-required={true}
                    />

                    <TextField
                        required
                        name="email"
                        label="Email address"
                        value={formData.email}
                        onChange={handleChange}
                        aria-required={true}
                    />

                    <TextField
                        required
                        name="phoneNumber"
                        label="Numéro de téléphone"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    aria-required={true}/>
                    <TextField
                        required
                        name="password"
                        label="Password"
                        error={!passwordValid && formData.password !== ""}
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange}
                        aria-required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {formData.password && (
                    <Stack spacing={1} alignItems="flex-start">
                        <div style={{ color: passwordChecks.validLength ? 'green' : 'red', fontSize: '0.8rem' }}>
                            {passwordChecks.validLength ? '✔︎' : '✗'} At least 8 characters long
                        </div>
                        <div style={{ color: passwordChecks.number ? 'green' : 'red', fontSize: '0.8rem' }}>
                            {passwordChecks.number ? '✔︎' : '✗'} Contains at least one number
                        </div>
                        <div className="" style={{ color: passwordChecks.uppercase ? 'green' : 'red', fontSize: '0.8rem' }}>
                            {passwordChecks.uppercase ? '✔︎' : '✗'} Contains at least one uppercase letter
                        </div>
                        <div style={{ color: passwordChecks.specialCharacter ? 'green' : 'red', fontSize: '0.8rem' }}>
                            {passwordChecks.specialCharacter ? '✔︎' : '✗'} Contains at least one special character
                        </div>
                    </Stack>
                        )}
                </Stack>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading || !passwordValid}
                    sx={{ my: 3 }}
                >
                    {loading ? <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', mt: '-12px', ml: '-12px' }} color="inherit" /> : "Créer un compte"}
                </LoadingButton>
            </form>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}} open={success} autoHideDuration={16000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
                   Compte crée avec Success
                </Alert>
            </Snackbar>
        </>

    );
}
