import React, {useState, useEffect} from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
} from '@mui/material';
import { useParams} from "react-router-dom";
import Navbar from "./invoice/navbar";
import {getClientById, getAllClientFactures} from "../api/routes/client";
import {styled} from "@mui/material/styles";
import NoInvoicesImage from "../assets/empty-state-facture.svg"
import {green,blue } from "@mui/material/colors";
import ClientCard from "../components/ClientCard";



const StatusTypography = styled(Typography)(({ status }) => ({
    fontWeight: 'bold',
    color: status === 'EST À JOUR' ? green[500] : blue[500], // Change colors as needed
    marginLeft: 8,
}));


const ClientDetailPage = () => {

    const {clientId} = useParams();
    const [client, setClient] = useState(null)
    const [factures, setFactures] = useState(null)
    const [loading, setLoading] = useState(true)
    const [totalTTC, setTotalTTC] = useState(0);


    useEffect(() => {

        // Fetch client details
        const fetchClientDetails = async () => {
            try {
                const clientData = await getClientById(clientId);
                const clientFactures = await getAllClientFactures(clientId);
                setClient(clientData);
                setFactures(clientFactures);

                const total = clientFactures.reduce((acc, invoice) => acc + invoice.totalTTC, 0);
                console.log("Client Factures Total :", total);
                setTotalTTC(total);

            } catch (error) {
                console.error('Failed to fetch client details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [clientId]);

    const getStatusMessage = () => {
        if (!factures || factures.length === 0) {
            return 'EST À JOUR';
        }
        // Assuming the latest invoice determines the client's status
        const latestInvoiceStatus = factures[factures.length - 1].status;
        return latestInvoiceStatus === 'PAYEE' ? 'EST À JOUR' : ' VOUS DOIT';
    };


    if(loading){
        return <Typography> Loading ...</Typography>
    }

    const getHeader = () => {
        return 'Client details ';
    };




    return (
        <div
        >
            <div
                style={{
                    width: "100%",
                    padding: "18px",
                    borderRadius: "8px",
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant="h4"
                    style={{textAlign: "left", paddingLeft: "300px"}}
                >
                    {getHeader()}
                </Typography>

            </div>

            <Box style={{
                width: "76%",
                padding: "94px",
                borderRadius: "8px",
                textAlign: "center", // Center the text
                marginTop: "50px",
                marginLeft: "auto", // Center the card
                marginRight: "auto", // Center the card
            }}>

                <ClientCard client={{ name: client.name, status: getStatusMessage() }} />
                <Box sx={{ padding: 3, width: '100%', maxWidth: 1600, mx: 'auto' }}>
                    {/* Table for Invoices */}
                    {factures && factures.length > 0 ? (
                            factures.map((invoice) => (
                    <TableContainer component={Paper} sx={{ margin: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Numéro</TableCell>
                                    <TableCell>Intitulé</TableCell>
                                    <TableCell>Montant HT</TableCell>
                                    <TableCell>Montant TTC</TableCell>
                                    <TableCell>Status</TableCell>
                                    {/*<TableCell align="right">Actions</TableCell>*/}
                                </TableRow>
                            </TableHead>

                            <TableBody>


                                    <TableRow key={invoice.id}>
                                        <TableCell>{invoice.dateEmission}</TableCell>
                                        <TableCell>{invoice.factureNumber}</TableCell>
                                        <TableCell>{invoice.designationService}</TableCell>
                                        <TableCell style={{fontWeight: "bold"}}>{invoice.totalHT.toFixed(2)} xaf</TableCell>
                                        <TableCell style={{fontWeight: "bold"}}>{invoice.totalTTC.toFixed(2)} xaf</TableCell>
                                        <TableCell>{invoice.status}</TableCell>
                                        {/*<TableCell align="right">
                                            <IconButton>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>*/}
                                    </TableRow>

                            </TableBody>

                        </Table>
                    </TableContainer>
                            ))
                    ):(
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                            <img src={NoInvoicesImage} alt="No invoices" style={{ maxWidth: '100%', height: 'auto' }} />
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                Ce client n'a pas encore de facture associée
                            </Typography>
                        </Box>
                    )}
                    {/* Add more sections like Devis as needed */}
                </Box>
            </Box>

<Navbar/>
        </div>
    );
};

export default ClientDetailPage;
