import {Card, Typography, IconButton, Avatar, Box, useTheme} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import {useNavigate, useParams} from "react-router-dom";
import {green, red} from "@mui/material/colors";

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff', // Assuming white background
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Custom shadow
    borderRadius: '20px', // Custom border radius
    padding: '20px', // Custom padding
    margin: 'auto', // Center the card
    maxWidth: '900px', // Max width of the card
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.info.main,
    marginRight: theme.spacing(2),
}));

const StyledSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
}));



const AmountTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));

const StatusTypography = styled(Typography)(({ status }) => ({
    fontWeight: 'bold',
    color: status === 'EST À JOUR' ? green[500] : red[500], // Change colors as needed
    marginLeft: 8,
}));

const ClientCard = ({ client }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { name, status, amount } = client;

    const handleClientInfoPage = () => {
        // Replace with your navigation logic
        navigate(`/ClientViewInfo/${client.id}`);
    }

    return (
        <StyledCard>
            <IconButton>
                <StyledInfoIcon />
            </IconButton>
            <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>{name?.[0]?.toUpperCase()}</Avatar>
            <Typography variant="h6">{name}</Typography>
            <StatusTypography>{status}</StatusTypography>
            <AmountTypography>{amount}</AmountTypography>
            <IconButton onClick={handleClientInfoPage}>
                <StyledSettingsIcon />
            </IconButton>
        </StyledCard>
    );
};

export default ClientCard;
