
// @mui
import {Avatar, Box, Button, Card, Divider, Drawer, Grid, Link, Stack, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {getClientById, getAllClientFactures} from "../../api/routes/client";

import {useNavigate, useParams} from "react-router-dom";
import {setAuthToken} from "../../api/axios";
import ClientUpdateForm from "./ClientUpdateForm";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {Skeleton} from "@mui/lab";
// ----------------------------------------------------------------------


const SummaryCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Adjust shadow to match your design
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
}));

// Styles for the User Information Card
const UserInfoCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}));


const AttachedCompaniesCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
}));



export default function ClientList() {

    const [client, setClient] = useState(null);
    const {clientId} = useParams();
    const [open, setOpen] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isUserDrawerOpen, setUserDrawerOpen] = useState(false);
    const [totalTTC, setTotalTTC] = useState(0);


    useEffect(() => {
        const fetchClient = async () => {
            try {
                const data = await getClientById(clientId);
                const clientFactures = await getAllClientFactures(clientId)

                console.log("Client info :", data);
                console.log("Client Factures :", clientFactures);
                setClient(data);

                const total = clientFactures.reduce((acc, invoice) => acc + invoice.totalTTC, 0);
                console.log("Client Factures Total :", total);
                setTotalTTC(total);
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    // Déconnectez l'utilisateur et redirigez-le vers la page de connexion
                    handleLogout();
                } else {
                    setError("Erreur lors de la récupération des données de l'entreprise");
                }
            }
        };
        fetchClient();
    }, []);
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        setAuthToken(false);
        navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
    };

    const getInitial = (name) => {
        if (name && typeof name === 'string') {
            return name[0].toUpperCase();
        }
        return '?'; // par défaut si le nom est absent ou invalide
    };

    const handleOpenUserDrawer = () => setUserDrawerOpen(true);
    const handleCloseUserDrawer = () => setUserDrawerOpen(false);

    // Créez une fonction pour fermer le Drawer
    const handleCloseDrawer = () => setDrawerOpen(false);
    const handleSuccessAndRefresh = async (updatedData) => {
        // Update the local state to reflect the updated client information
        setClient(prevState => ({
            ...prevState,
            client: updatedData
        }));
        handleCloseDrawer();
    };


        return (

            <>
                <Grid container spacing={3}>
                    {/* Client Profile Information */}
                    <Grid item xs={6} md={6}>
                        <UserInfoCard>

                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <StyledAvatar>{client && client ? (
                                        <>
                                            <Avatar>{getInitial(client.name)}</Avatar>
                                        </>
                                    ) : (
                                        <Avatar>?</Avatar>
                                    )}</StyledAvatar>
                                    <Typography variant="subtitle1" sx={{fontWeight: 'medium', alignSelf: 'flex-start'}}>Profil
                                        Client</Typography>
                                    <Box sx={{width: '100%', mt: 2}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Nom</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.name : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Email</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.email : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Iban</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.iban : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Bic</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.bic : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Adresse</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.address : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Contact</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.contact : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Ville</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.town : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Note</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2"
                                                            sx={{color: 'text.secondary'}}>{client ? client.note : <Skeleton animation="pulse" variant="text" width="60%" />}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Button variant="outlined" item xs={6} sx={{mt: 2}}
                                            onClick={handleOpenUserDrawer}>Modifier</Button>
                                    <Button color="error" variant="outlined" item xs={6} sx={{mt: 2}}
                                            onClick={handleOpenUserDrawer}>Supprimer</Button>
                                    <Drawer
                                        anchor="right"
                                        open={isUserDrawerOpen}
                                        onClose={handleCloseUserDrawer}
                                    >
                                        <Box p={2} width='450px' textAlign='center'>
                                            <Typography variant='h6' component='div' sx={{my: 3}}>
                                                Mettre à jour les informations
                                            </Typography>
                                            {/* Pass the user data to the ParameterForm */}
                                            {client && (
                                                <ClientUpdateForm id={client.id} initialData={client}
                                                                  onSuccess={handleSuccessAndRefresh}/>
                                            )}
                                            <Divider/>
                                        </Box>
                                    </Drawer>

                                </Box>



                        </UserInfoCard>


                    </Grid>

                </Grid>
            </>

        );
    }

