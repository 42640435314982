import axios, {setAuthToken} from "../../axios";

export const createInvoice = async (userData) => {
    try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            throw new Error('No token found');
        }

        // No need to extract enterpriseId from token anymore as it is passed as a parameter

        setAuthToken(token);

        // If the token exists, make the request
        const response = await axios.post('/facture/create', userData);

        // Return the response data
        return response.data;

    } catch (error) {
        // More precise error handling
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}




export const getAllInvoice = async () => {
    try {
        // Vérifiez s'il y a un token dans localStorage et si oui,
        // mettez-le dans les headers de la requête axios
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.get('/facture/all');

        // Retournez les données de la réponse
        return response.data;
    } catch (error) {
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
};

export const getInvoiceById = async (id) => {
    try {
        // Vérifiez s'il y a un token dans localStorage et si oui,
        // mettez-le dans les headers de la requête axios
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.get(`/facture/${id}`);

        // Retournez les données de la réponse
        return response.data;
    } catch (error) {
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }

};

export const statusFacturee = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/facture/${id}/status/facturee`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}



export const statusEnvoyee = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/facture/${id}/status/envoyee`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}



export const statusPayee = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/facture/${id}/status/payee`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}


export const downloadInvoice = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.get(`/facture/download/${id}/pdf`,{
            responseType: 'blob', // Important pour les fichiers PDF
        });

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}


export const deleteInvoiceById = async (id) => {
    if(!id){
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to deleteInvoiceById.');
    }

    try {
        const token = localStorage.getItem('jwtToken');
        if(!token){
            throw new Error('Token not Found');
        }

        setAuthToken(token);

        const response = await axios.delete(`/facture/delete/${id}`)
        return response.data;
    }catch(error){
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }

}