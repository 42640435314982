import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components


import ClientList from "../sections/client/ClientList";
import Navbar from "./devis/navbar";

export default function ClientViewInfo() {

    return (
        <div style={{
            width: "100%",
            backgroundColor: "white",
            padding: "18px",
            borderRadius: "8px",
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
        }}>
            <Helmet>
                <title> Dashboard: Client Information | Factura</title>
            </Helmet>

            <Container style={{
                width: "70%",
                backgroundColor: "white",
                padding: "24px",
                borderRadius: "8px",
                textAlign: "start",
                marginTop: "50px",
                marginLeft: "300px",
            }}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Information client
                </Typography>
                <ClientList />

            </Container>

            <Navbar/>
        </div>
    );
}
