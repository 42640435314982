import axios, {setAuthToken} from "../../axios";

export const createDevis = async (userData) => {
    try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            throw new Error('No token found');
        }

        // No need to extract enterpriseId from token anymore as it is passed as a parameter

        setAuthToken(token);

        // If the token exists, make the request
        const response = await axios.post('/devis/create', userData);

        // Return the response data
        return response.data;

    } catch (error) {
        // More precise error handling
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}




export const getAllDevis = async () => {
    try {

        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {

            throw new Error('No token found');
        }

        const response = await axios.get('/devis/all');

        return response.data;
    } catch (error) {
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
};

export const getDevisById = async (id) => {

    if (!id) {
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to getDevisById.');
    }
    try {

        const token = localStorage.getItem('jwtToken');
        if (!token) {
            throw new Error('No token found');

        }
        setAuthToken(token);
        // Si le token existe, On effectue la requête
        const response = await axios.get(`/devis/${id}`);
        // Retourne les données de la réponse
        return response.data;
    } catch (error) {
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }

};


export const statusEnregistre = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/devis/${id}/status/enregriste`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}



export const statusAccepte = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/devis/${id}/status/accepte`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}


export const statusAnnule = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/devis/${id}/status/annule`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}

export const statusRefuse = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/devis/${id}/status/refuse`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}


export const convertirDevisEnFacture = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/devis/${id}/convertir-en-facture`);

        // Retournez les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}
export const downloadDevis = async(id) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lance une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, On effectue la requête
        const response = await axios.get(`/devis/download/${id}/pdf`,{
            responseType: 'blob', // Important pour les fichiers PDF
        });

        // On Retourne les données de la réponse
        return response.data;
    }catch (error){
        // Gestion d'erreurs plus précise
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}


export const deleteDevisById = async (id) => {
    if(!id){
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to deleteDevisById.');
    }

    try {
        const token = localStorage.getItem('jwtToken');
        if(!token){
            throw new Error('Token not Found');
        }

        setAuthToken(token);

        const response = await axios.delete(`/devis/${id}`)
        return response.data;
    }catch(error){
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }

}