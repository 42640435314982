import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination, Box, Divider,
} from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { Drawer } from '@mui/material';
import {ClientForm} from "../sections/client";
import {deleteClientById, getAllClient} from "../api/routes/client";
import {useNavigate} from "react-router-dom";

const TABLE_HEAD = [
  { id: 'Name', label: 'Nom', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Address', label: 'Adresse', alignRight: false },
  { id: 'Contact', label: 'Contact', alignRight: false },
  { id: 'town', label: 'Ville', alignRight: false },


];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

  const [getClient, setGetClient] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [currentClientId, setCurrentClientId] = useState(null);

  const navigate = useNavigate();

  useEffect(()=> {
    const getAllClients = async ()=> {
      try{
        const data = await getAllClient();
        setGetClient(data);
        console.log(data)
      }catch (error){
        setError("Error getting all sessions")
      }
    };
    getAllClients();
  },[]);

  // Ajoutez un nouvel hook d'état pour gérer l'état du Drawer
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Créez une fonction pour ouvrir le Drawer
  const handleOpenDrawer = () => setDrawerOpen(true);

  // Créez une fonction pour fermer le Drawer
  const handleCloseDrawer = () => setDrawerOpen(false);
  const handleOpenMenu = (event, clientId) => {
    setOpen(event.currentTarget);
    setCurrentClientId(clientId); // Définir l'ID du client actuel lorsque le menu est ouvert
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getClient ? getClient.map((n) => n.name) : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (getClient?.length || 0)) : 0;


  const filteredSessions = applySortFilter(getClient || [], getComparator(order, orderBy), filterName);


  const isNotFound = !filteredSessions.length && !!filterName;

  const refreshSession = async () => {
    try{
      const data = await getAllClient();
      setGetClient(data);
      handleCloseDrawer();
    }catch (error) {
      setError("Error getting all sessions");
    }
  }

  const handleSuccessAndRefresh = async () => {
    await refreshSession();
  }

 const handleDelete = async (particularClientId) => {

   const clientToDelete = getClient.find(client => client.id === particularClientId);

   // Vérifier si le client existe bien avant de continuer
   if (clientToDelete && window.confirm(`Êtes-vous sûr de vouloir supprimer le client ${clientToDelete.name}?`)) {
     try {
       await deleteClientById(particularClientId);
       console.log("Attempting to delete client with ID:", particularClientId);
       const updatedClients = getClient.filter(client => client.id !== particularClientId);
       setGetClient(updatedClients);
       alert("Client successfully deleted.");
     } catch (error) {
       console.error("Error deleting the client:", error.message);
       alert("Une erreur s'est produite lors de la suppression du client. Veuillez réessayer.");
     }
   }
  };

  const handleClientDetailsPage = async(clientId) => {

    navigate(`/ClientDetailPage/${clientId}`);

  }


  return (
      <>
        <Helmet>
          <title> Create Client | Factura </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Clients
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenDrawer}>
              Nouveau Client
            </Button>


          </Stack>

          <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
          >
            <Box p={2} width='450px' textAlign='center' >
              <Typography variant='h6' component='div' sx={{ my: 3 }}>
                Créer un client
              </Typography>
              <ClientForm onSuccess={handleSuccessAndRefresh}/>

              <Divider/>
            </Box>

          </Drawer>



          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={getClient ? getClient.length : 0}
                      count={getClient ? getClient.length : 0}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, email, iban, bic, town, address, note, contact } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                          <TableRow style={{cursor: "pointer"}}  onClick={() => handleClientDetailsPage(id)}  hover key={id} tabIndex={-1} selected={selectedUser}>
                            <TableCell padding="checkbox">

                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={name} />
                                <Typography variant="subtitle2" noWrap>
                                  {name ? name : 'Loading...'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {email ? email : "Pas d'email"}
                            </TableCell>
                            <TableCell align="left">
                              {address ? address : 'No bic found'}
                            </TableCell>
                            <TableCell align="left">
                              {contact ? contact : 'No contact found'}
                            </TableCell>
                            <TableCell align="left">
                              {town ? town : 'No town found'}
                            </TableCell>


                        {/*    <TableCell align="left">
                              {questionnaire ? questionnaire.name : 'No questionnaire found'}
                            </TableCell>*/}


                            {/*<TableCell align="right">
                              <IconButton onClick={(event) => handleOpenMenu(event, id)} size="large" color="inherit">
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>*/}
                          </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getClient ? getClient.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        {/*<Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
        >
        <MenuItem >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

          <MenuItem onClick={() => handleDelete(currentClientId)} sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>

          <MenuItem onClick={() => handleDelete(currentClientId)} sx={{ color: 'green' }}>
            <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
            View
          </MenuItem>

        </Popover>*/}
      </>
  );
}
