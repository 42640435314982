import React, {useState, useEffect} from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper, Grid } from '@mui/material';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import Navbar from "./devis/navbar";
import {getAllClient, getClientById} from "../api/routes/client";
import {getUserInfo} from "../api/routes/user";
import {getDevisById} from "../api/routes/devis";

const DevisDetailsPageView = () => {

    const { devisId } = useParams();
    const [devisDetails, setDevisDetails] = useState(null);
    const [user, setUser] = useState(null);
    const [clientDetails, setClientDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [open, setOpen] = useState(null);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const devisDataResponse = await getDevisById(devisId);
                const userInfoResponse = await getUserInfo();

                console.log('Devis Data:', devisDataResponse);
                console.log('User Info:', userInfoResponse);

                if (!devisDataResponse || !userInfoResponse) {
                    throw new Error('One or more responses are undefined.');
                }

                // Assuming that the data is directly returned by the API call
                setDevisDetails(devisDataResponse);
                setUser(userInfoResponse);

                if (!devisDataResponse.clientId) {
                    throw new Error('Devis does not have a clientId.');
                }

                const clientDataResponse = await getClientById(devisDataResponse.clientId);
                console.log('Client Data:', clientDataResponse);

                if (!clientDataResponse) {
                    throw new Error('Client response is undefined.');
                }

                setClientDetails(clientDataResponse);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message || "An unexpected error occurred");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
        }, [devisId]);



    const getHeader = () => {
        return 'Créer un dévis ';
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    return (
        <div
            style={{
                width: "100%",
                padding: "18px",
                borderRadius: "8px",
                marginBottom: "16px",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Navbar />
            {isLoading ? (
                <Typography>Loading...</Typography>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (

                devisDetails && user && clientDetails && (
                    <Box style={{
                        width: "56%",
                        padding: "94px",
                        borderRadius: "8px",
                        textAlign: "center",
                        marginTop: "50px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>
                        <Box component="main" className="main-content">
                            <Paper sx={{ px: 2, py: 3 }}>
                                <Grid container justifyContent="space-between" sx={{ textAlign: 'left' }}>
                                    <Grid item xs={12} md={6}>
                                        {user && (
                                            <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: '50px' }}>
                                                {user.enterprise?.enterpriseName}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {devisDetails.dateEmission} | {devisDetails.devisNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Box sx={{ backgroundColor: 'action.selected', px: 5, py: 3, textAlign: 'left' }}>
                                <Grid container justifyContent="space-between">
                                    {user && (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                {user.enterprise?.enterpriseName}<br />
                                                {user.email}<br />
                                                {user.phoneNumber}<br />
                                                {user.enterprise?.siegeSocial}
                                            </Typography>
                                        </Grid>
                                    )}


                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {clientDetails.name}<br />
                                            {clientDetails.email}<br />
                                            {clientDetails.contact}<br />
                                            {clientDetails.address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Paper sx={{ px: 5, py: 5 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Désignation</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Quantité</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Prix Unitaire</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {devisDetails.lignesDevis.map((ligne) => (
                                            <TableRow key={ligne.id}>
                                                <TableCell>{ligne.designation}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{ligne.quantite}</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{ligne.prixUnitaireHT}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={7} sx={{ fontWeight: 'light', textAlign: 'right', color: "grey" }}>Net total:  {devisDetails.totalHT} XAF </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                <span style={{
                                                    backgroundColor: 'black',
                                                    borderRadius: '5px',
                                                    padding: '10px',
                                                    color: 'white',

                                                }} >Total: {devisDetails.totalHT} XAF </span>

                                            </TableCell>
                                        </TableRow>
                                        {/* ...additional rows for totals */}
                                    </TableBody>
                                </Table>
                            </Paper>

                            {/* ...additional divs for terms & conditions, signature */}

                        </Box>

                        <Box component="footer" sx={{ backgroundColor: 'action.selected', px: 5, py: 2, textAlign: 'left' }}>
                            {user && (
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body2">
                                            {user.enterprise?.enterpriseName}<br />
                                            {user.email}<br />
                                            {user.enterprise.iban}<br />
                                            {user.enterprise.bic}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="body2">
                                            {user.enterprise?.formeJuridique}<br />
                                            {user.enterprise?.registreCommerce}<br />
                                            {user.enterprise?.siegeSocial}<br />
                                            {user.phoneNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                        </Box>
                    </Box>



                )
            )}
            {error && <Typography color="error">{error}</Typography>}
        </div>
    )
}

export default DevisDetailsPageView