import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import {setAuthToken} from "./api/axios";

const TOKEN_EXPIRATION_MILLIS = 7200000;

export default function App() {

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const tokenTimestamp = localStorage.getItem('jwtTokenTimestamp');
        const currentTime = new Date().getTime();

        if (token && tokenTimestamp && (currentTime - tokenTimestamp) < TOKEN_EXPIRATION_MILLIS) {
            setAuthToken(token);
        } else {
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('jwtTokenTimestamp');
            // Here you could also redirect to login or dispatch a logout action
        }
    }, []);

    return (
        <HelmetProvider>
            <BrowserRouter>
                <ThemeProvider>
                    <ScrollToTop />
                    <StyledChart />
                    <Router />
                </ThemeProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
}
