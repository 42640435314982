import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import InvoicePage from './pages/InvoicePage'
import CreateInvoicePage from "./pages/CreateInvoicePage";
import PrivateRoute from "./PrivateRoutes";
import RegisterPage from "./pages/RegisterPage";
import DevisPage from "./pages/DevisPage";
import CreateDevisPage from "./pages/CreateDevisPage";
import VerifyPage from "./pages/VerifyPage";
import ParameterPage from "./pages/ParameterPage";
import ClientDetailPage from "./pages/ClientDetailPage";
import ClientViewInfo from "./pages/ClientViewInfo";
import DevisDetailsPageView from "./pages/DevisDetailsPageView";
import FactureDetailsPageView from "./pages/FactureDetailsPageView";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    { path: '/', element: <Navigate to="/dashboard/user" replace /> },
    {
      path: '/dashboard',
      element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
      children: [
        { element: <Navigate to="/dashboard/user" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'facture', element: <InvoicePage /> },
        {path: 'devis', element: <DevisPage /> },
        {path: 'paramètre', element: <ParameterPage /> },

      ],
    },
    {
      path: 'facture',
      element: <InvoicePage />
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'verify',
      element: <VerifyPage />,
    },
    {
      path: '/CreateInvoicePage/',
      element: <CreateInvoicePage/>,
    },
    {
      path: '/CreateDevisPage/',
      element: <CreateDevisPage/>,
    },
    {
      path: '/ParameterPage',
      element: <ParameterPage/>,
    },
    {
      path: '/ClientDetailPage/:clientId',
      element: <ClientDetailPage/>,
    },
    {
      path: '/DevisDetailsPageView/:devisId',
      element: <DevisDetailsPageView/>,
    },
    {
      path: '/FactureDetailsPageView/:invoiceId',
      element: <FactureDetailsPageView/>,
    },

    {
      path: '/ClientViewInfo/:clientId',
      element: <ClientViewInfo/>,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/user" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
