import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import {getUserInfo} from "../../../api/routes/user";
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  const[error, setError] = useState(null);
  const isDesktop = useResponsive('up', 'lg');



  useEffect(() => {
    const fetchUser = async () => {
      try{
        const data = await getUserInfo();
        setUser(data);
      }catch (error){
        setError("Erreur lors de la récupération des données de l'\entreprise")
      }
    };

    fetchUser();

    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getInitial = (name) => {
    if (name && typeof name === 'string') {
      return name[0].toUpperCase();
    }
    return '?'; // par défaut si le nom est absent ou invalide
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }} >
        <Link underline="none">
          <StyledAccount>
            {user && user.enterprise ? (
                <>
                  <Avatar>{getInitial(user.enterprise.enterpriseName)}</Avatar>
                </>
            ) : (
                <Avatar>?</Avatar>
            )}

            <Box sx={{ ml: 2 }}>
              {user && user.enterprise ? (
                  <>
                    <Typography variant="body2" style={{fontWeight: "bold", color: "#000000"}}>{user.enterprise.enterpriseName}</Typography>
                    {/* Add more details as needed */}
                  </>
              ) : (
                  <Typography variant="body2">Loading enterprise details...</Typography>
              )}

              <Typography variant="body2" sx={{ color: 'dark' }}>

              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
