import axios, {setAuthToken} from "../../axios";

export const UpdateEnterpriseInfo = async (id, userData) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/enterprise/update/${id}`, userData);
        // Retournez les données de la réponse
        return response.data;
    }catch (error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}