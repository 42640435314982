import React, {useState, useEffect} from "react";
import {
    Box,
    Paper,
    Grid,
    Typography,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    InputAdornment, TableFooter, Stack, Drawer, Divider,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import Navbar from "./devis/navbar";
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {getAllClient} from "../api/routes/client";
import {getUserInfo} from "../api/routes/user";
import {createDevis} from "../api/routes/devis";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { format } from 'date-fns';
import {ClientForm} from "../sections/client";


const CreateDevisPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [devisDate, setDevisDate] = useState(new Date());
    const [clientModalOpen, setClientModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState({
        name: '',
        address: '',
        town: '',
        email: '',
        contact: '',
    });
    const [dateEmission, setDateEmission] = useState(null);
    const [dateEcheance, setDateEcheance] = useState(null);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [getClient, setGetClient] = useState([]);
    const [error, setError] = useState(null);
    const [newDevis, setNewDevis] = useState({
        devisNumber: '',
        pdfPath: '',
        conditionsPaiement: '',
        designationService: '',
        dateEmission: null,
        dateEcheance: null,
        clientId: selectedClient?.id || null,
        entrepriseId: user?.enterprise?.id || null,
        lignesDevis: [],
        totalHT: subtotal,
        totalTTC: total,
        notes: '',
        status: 'Draft', // Or any other default status
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    useEffect(() => {
        const getAllClients = async () => {
            try {
                const data = await getAllClient();
                setGetClient(data);
            } catch (error) {
                setError("Error getting all sessions")
            }
        };
        getAllClients();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const data = await getUserInfo();
                setUser(data);
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    // Déconnection de l'utilisateur et redirection vers la page de connexion

                } else {
                    setError("Erreur lors de la récupération des données de l'entreprise");
                }
            }
        };
        fetchUser();
    }, []);

// Utilisation d'un effet pour calculer les totaux chaque fois que les articles changent
    useEffect(() => {
        const newSubtotal = items.reduce((sum, item) => sum + item.quantity * item.price, 0);
        const newTotal = newSubtotal; // Ajoutez ici des calculs pour la TVA ou d'autres frais si nécessaire
        setSubtotal(newSubtotal);
        setTotal(newTotal);
    }, [items]);


    useEffect(() => {
        // Mise à jour de newDevis lorsque selectedClient change
        setNewDevis(prevDevis => ({
            ...prevDevis,
            clientId: selectedClient?.id || null
        }));

        console.log()
    }, [selectedClient]);

// Function to handle selecting a client
    const handleSelectClient = (client) => {
        setSelectedClient({
            id: client.id,
            name: client.name,
            address: client.address,
            town: client.town,
            contact: client.contact,
            email: client.email,
        });
        setClientModalOpen(false);
        console.log("Client sélectionné:", client);
    };

    const refreshSession = async () => {
        try{
            const data = await getAllClient();
            setGetClient(data);
            handleCloseDrawer();
        }catch (error) {
            setError("Error getting all sessions");
        }
    }
    // Créez une fonction pour ouvrir le Drawer
    const handleOpenDrawer = () => {
        setDrawerOpen(true);
        setClientModalOpen(false);
    }

    // Créez une fonction pour fermer le Drawer
    const handleCloseDrawer = () => setDrawerOpen(false);

    // Cette fonction sera appelée lorsque le devis est créé avec succès
    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
    const handleSuccessAndRefresh = async () => {
        await refreshSession();
    }

// Cette fonction sera appelée pour fermer le Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleCreateDevis = async () => {
        try {

            const devisData = {
                ...newDevis,
                clientId: selectedClient?.id,
                entrepriseId: user?.enterprise?.id,
                dateEmission: format(dateEmission, "dd-MM-yyyy"), // Formatage de la q
                dateEcheance: format(dateEcheance, "dd-MM-yyyy"), // Formatage de la date d'échéance
                lignesDevis: items.map(item => ({
                    designation: item.title,
                    quantite: item.quantity,
                    prixUnitaireHT: item.price,
                    // totalLigneHT est calculé dans le backend
                })),
                // Autres champs...
            };

            // Call the function to create the devis
            const createdDevis = await createDevis(devisData);
            handleSnackbarOpen('Le devis a été créé avec succès.');
            navigate('/dashboard/devis');
            // Here you might want to navigate the user to another page or show success message
            console.log('Devis created successfully:', createdDevis);

        } catch (error) {
            // Handle errors
            handleSnackbarOpen('Erreur lors de la création du devis.');
            console.error('Error creating devis:', error);
        }
    };





// Function to open the modal
    const handleOpenClientModal = async () => {

        try {
            // Fetch clients when the modal opens
            const fetchedClients = await getAllClient();
            setGetClient(fetchedClients);
        } catch (error) {
            console.error('Error fetching clients:', error);
            alert('Failed to load clients.');
        }
        setClientModalOpen(true);

    };

// Function to close the modal
    const handleCloseClientModal = () => {
        setClientModalOpen(false);
    };


    const handleAddItem = () => {
        const newItem = {
            title: '',
            description: '',
            service: '',
            quantity: 1,
            price: 0.00
        };
        setItems([...items, newItem]);
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((item, i) => i !== index));
    };
    // .
    const getHeader = () => {
        return 'Créer un dévis ';
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };
// Fonction pour gérer le changement de statut
    const handleStatusChange = (event) => {
        setNewDevis({...newDevis, status: event.target.value});
    };

    const handleDateEmissionChange = (newValue) => {
        setDateEmission(newValue); // Mise à jour de l'état dateEmission
        setNewDevis(prevDevis => ({
            ...prevDevis,
            dateEmission: format(newValue, "dd-MM-yyyy") // Mise à jour de newDevis
        }));
    };

    const handleDateEcheanceChange = (newValue) => {
        setDateEcheance(newValue); // Mise à jour de l'état dateEcheance
        setNewDevis(prevDevis => ({
            ...prevDevis,
            dateEcheance: format(newValue, "dd-MM-yyyy") // Mise à jour de newDevis
        }));
    };


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "#f0f0f0",
                width: "100%",
                flex: 1,
                justifyContent: "space-between"
            }}
        >
            <div
                style={{
                    width: "100%",
                    backgroundColor: "white",
                    padding: "18px",
                    borderRadius: "8px",
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant="h4"
                    style={{textAlign: "left", paddingLeft: "300px"}}
                >
                    {getHeader()}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    style={{fontSize: "16px", width: "10%"}}
                >
                    Sauvegarder
                </Button>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Paper style={{
                    width: "70%",
                    backgroundColor: "white",
                    padding: "24px",
                    borderRadius: "8px",
                    textAlign: "start",
                    marginTop: "50px",
                    marginLeft: "300px",
                }}>
                    <Typography variant="h6" sx={{mb: 2}}></Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} style={{paddingRight: 30, borderRight: '2px solid #d6d4d4'}}>
                            {/* Sender's details */}
                            <Typography variant="body1" style={{fontWeight: 'bold', color: "gray"}}
                                        gutterBottom>De:</Typography>
                            {user && user.enterprise ? (
                                <>
                                    <Typography variant="body2">{user.enterprise.enterpriseName}</Typography>
                                    <Typography variant="body2">{user.enterprise.siegeSocial}</Typography>
                                    <Typography variant="body2">{user.enterprise.postalCode}</Typography>
                                    <Typography variant="body2">{user.email}</Typography>
                                    <Typography variant="body2">{user.phoneNumber}</Typography>
                                </>
                            ) : (
                                <Typography variant="body2">Loading enterprise details...</Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6} style={{paddingLeft: 30}}>
                            <Typography variant="body1" style={{fontWeight: 'bold', color: "gray"}}
                                        gutterBottom>Client:</Typography>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    {selectedClient.name ? (
                                        <>
                                            <Typography variant="body2">{selectedClient.name}</Typography>
                                            <Typography variant="body2">{selectedClient.address}</Typography>
                                            <Typography variant="body2">{selectedClient.city}</Typography>
                                            <Typography variant="body2">{selectedClient.country}</Typography>
                                            <Typography variant="body2">{selectedClient.phone}</Typography>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                </Box>
                                <IconButton onClick={handleOpenClientModal} size="large">
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container spacing={4} sx={{my: 2}}>
                        <Grid item xs={6}>
                            <TextField
                                label="Intitulé du devis"
                                placeholder="Intitulé du devis"
                                variant="outlined"
                                fullWidth
                            >
                                {newDevis.designationService}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <DatePicker
                                label="Date d'émission"
                                value={dateEmission}
                                onChange={handleDateEmissionChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DatePicker
                                label="Date d'échéance"
                                value={dateEcheance}
                                onChange={handleDateEcheanceChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </Grid>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{
                                    backgroundColor: '#005799',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem', // Taille de police
                                    padding: '10px 16px',
                                    textAlign: 'center',
                                    borderTopLeftRadius: '8px', // Rayon de bordure pour les coins arrondis
                                    borderBottomLeftRadius: '8px',
                                    borderRight: '2px solid white' // Bordure droite pour créer l'espacement
                                }}>Designation</TableCell>
                                <TableCell align="center" style={{
                                    backgroundColor: '#005799',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    padding: '10px 16px',
                                    textAlign: 'center',
                                    borderRight: '2px solid white'
                                }}>Quantité</TableCell>
                                <TableCell align="center" style={{
                                    backgroundColor: '#005799',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    padding: '10px 16px',
                                    textAlign: 'center',
                                    borderRight: '2px solid white'
                                }}>Prix Unitaire</TableCell>
                                <TableCell align="center" style={{
                                    backgroundColor: '#005799',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    padding: '10px 16px',
                                    textAlign: 'center',
                                    borderRight: '2px solid white'
                                }}>Total</TableCell>
                                <TableCell align="center" style={{
                                    backgroundColor: '#005799',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    padding: '10px 16px',
                                    textAlign: 'center',
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderRight: '2px solid white'
                                }} colSpan={2}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={item.title}
                                            onChange={(e) => handleItemChange(index, 'title', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={item.price}
                                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>{(item.quantity * item.price).toFixed(2)}</TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                            <DeleteIcon style={{color: "red"}} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <Button sx={{ my: 5 }} style={{color: "green"}} startIcon={<AddIcon />} onClick={handleAddItem}>Add Item</Button>
                        <TableFooter>
                            <TableRow>
                                <TableCell style={{ border: 0 }} colSpan={4}></TableCell>
                                <TableCell align="right" style={{ border: 0, color: "#005799" }}>Total HT</TableCell>
                                <TableCell align="right" style={{ border: 0, color: "#005799" }}>{subtotal.toFixed(2)} XAF</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }} colSpan={4}></TableCell>
                                <TableCell align="right" style={{ border: 0, fontWeight: 'bold', color: "#005799" }}>Total TTC</TableCell>
                                <TableCell align="right" style={{ border: 0, borderRadius: 2, fontWeight: 'bold', color: "#005799" }}>{total.toFixed(2)} XAF</TableCell>
                            </TableRow>
                        </TableFooter>

                    </Table>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Notes"
                            placeholder="Add your notes here"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Box width="100%" style={{color: "gray"}} textAlign="center" mt={5} mb={1}>
                        {/*<Typography sx={{fontSize: 9}} variant="body2">
                            SIREN 904163011 - NAF 7311Z
                        </Typography>
                        <Typography sx={{fontSize: 9}} variant="body2">
                            TVA intracommunautaire : FR57904163011
                        </Typography>*/}
                    </Box>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="outlined">Visualiser le dévis</Button>
                            <Button onClick={handleCreateDevis} variant="contained" color="primary">
                                Créer le dévis
                            </Button>
                        </Stack>
                    </Grid>
                </Paper>
            </LocalizationProvider>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <Box p={2} width='450px' textAlign='center' >
                    <Typography variant='h6' component='div' sx={{ my: 3 }}>
                        Créer un client
                    </Typography>
                    <ClientForm onSuccess={handleSuccessAndRefresh}/>

                    <Divider/>
                </Box>

            </Drawer>

            <Modal open={clientModalOpen} onClose={handleCloseClientModal} aria-labelledby="client-modal-title"
                   aria-describedby="client-modal-description">
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography id="client-modal-title" variant="h6" component="h2">
                        Choisir un client
                    </Typography>
                    <TextField
                        id="search"
                        type="text"
                        fullWidth
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {/* Icon or element */}
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Cherchez un client..."
                        // onChange={...} // Optionally implement search functionality
                    />
                    <List sx={{pt: 0}}>
                        {/* Check if getClient is an array and has length before mapping over it */}
                        {Array.isArray(getClient) && getClient.length > 0 ? (
                            getClient.map((client, index) => (
                                <ListItem button key={client.id} onClick={() => handleSelectClient(client)}>
                                    <ListItemText primary={client.name}
                                                  secondary={`${client.address}, ${client.city}`}/>
                                </ListItem>
                            ))
                        ) : (
                            <Typography>Pas de clients</Typography>

                            //Ajout d'un boutton de création de client

                        )}


                    </List>
                    <Button sx={{ my: 5 }} style={{color: "green"}} startIcon={<AddIcon />} onClick={handleOpenDrawer}>Ajouter un client</Button>


                </Box>
            </Modal>
            <Navbar/>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CreateDevisPage;
