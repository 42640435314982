import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components

// mock
import {ParameterList} from "../sections/@dashboard/parameters";

// ----------------------------------------------------------------------

export default function ParameterPage() {

    return (
        <>
            <Helmet>
                <title> Dashboard: Paramètre | Factura</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Paramètre Utilisateur
                </Typography>
                <ParameterList />
            </Container>
        </>
    );
}
