import PropTypes from 'prop-types';
// @mui
import {Avatar, Box, Button, Card, Divider, Drawer, Grid, Link, Stack, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {getUserInfo} from "../../../api/routes/user";
import {useNavigate} from "react-router-dom";
import {setAuthToken} from "../../../api/axios";
import ParameterForm from "./ParameterForm";
import EnterpriseUpdateForm from "./EnterpriseUpdateForm";

// ----------------------------------------------------------------------



const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
}));

// Styles for the User Information Card
const UserInfoCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}));

// ----------------------------------------------------------------------

const AttachedCompaniesCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
}));



export default function ParameterList() {

    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isUserDrawerOpen, setUserDrawerOpen] = useState(false);
    const [isCompanyDrawerOpen, setCompanyDrawerOpen] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const data = await getUserInfo();
                console.log("User info :", data);
                setUser(data);
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    // Déconnectez l'utilisateur et redirigez-le vers la page de connexion
                    handleLogout();
                } else {
                    setError("Erreur lors de la récupération des données de l'entreprise");
                }
            }
        };
        fetchUser();
    }, []);
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        setAuthToken(false);
        navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
    };

    const getInitial = (name) => {
        if (name && typeof name === 'string') {
            return name[0].toUpperCase();
        }
        return '?'; // par défaut si le nom est absent ou invalide
    };

    const handleOpenUserDrawer = () => setUserDrawerOpen(true);
    const handleCloseUserDrawer = () => setUserDrawerOpen(false);

    // Handlers for Company Drawer
    const handleOpenCompanyDrawer = () => setCompanyDrawerOpen(true);
    const handleCloseCompanyDrawer = () => setCompanyDrawerOpen(false);
    // Créez une fonction pour fermer le Drawer
    const handleCloseDrawer = () => setDrawerOpen(false);
    const handleSuccessAndRefresh = async (updatedData) => {
        // Update the local state to reflect the updated enterprise information
        setUser(prevState => ({
            ...prevState,
            enterprise: updatedData
        }));
        handleCloseDrawer();
    };
    return (

      <>
          <Grid container spacing={3}>
              {/* User Profile Section */}
              <Grid item xs={12} md={6}>
                  <UserInfoCard>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <StyledAvatar>{user && user.enterprise ? (
                              <>
                                  <Avatar>{getInitial(user.enterprise.enterpriseName)}</Avatar>
                              </>
                          ) : (
                              <Avatar>?</Avatar>
                          )}</StyledAvatar>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', alignSelf: 'flex-start' }}>Mon profil</Typography>
                          <Box sx={{ width: '100%', mt: 2 }}>
                              <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                      <Typography variant="subtitle2">Nom</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user ? user.lastName : 'Loading...'}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography variant="subtitle2">Prénom</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user ? user.firstName : 'Loading...'}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography variant="subtitle2">Email</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user ? user.email : 'Loading...'}</Typography>
                                  </Grid>

                              </Grid>
                          </Box>
                          <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOpenUserDrawer}>Modifier</Button>
                          <Drawer
                              anchor="right"
                              open={isUserDrawerOpen}
                              onClose={handleCloseUserDrawer}
                          >
                              <Box p={2} width='450px' textAlign='center'>
                                  <Typography variant='h6' component='div' sx={{ my: 3 }}>
                                      Mettre à jour les informations
                                  </Typography>
                                  {/* Pass the user data to the ParameterForm */}
                                  {user && (
                                      <ParameterForm id={user.id} initialData={user} onSuccess={handleSuccessAndRefresh} />
                                  )}
                                  <Divider />
                              </Box>
                          </Drawer>

                      </Box>
                  </UserInfoCard>
              </Grid>

{/*               Attached Companies Section
              <Grid item xs={12} md={6}>
                  <AttachedCompaniesCard>
                      <Typography variant="h6" gutterBottom>Sociétés rattachées</Typography>
                      <Box>
                          {user && user.enterprise ? (
                              <>
                                  <Typography variant="subtitle1">{user.enterprise.enterpriseName}</Typography>
                              </>
                          ) : (
                              <Typography variant="body2">Loading enterprise details...</Typography>
                          )}

                      </Box>
                  </AttachedCompaniesCard>
              </Grid>*/}


              {/* Company Section */}

              <Grid item xs={12} md={6}>
                  <UserInfoCard>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <StyledAvatar>{user && user.enterprise ? (
                              <>
                                  <Avatar>{getInitial(user.enterprise.enterpriseName)}</Avatar>
                              </>
                          ) : (
                              <Avatar>?</Avatar>
                          )}</StyledAvatar>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', alignSelf: 'flex-start' }}>Informations de l'entreprise</Typography>
                          <Box sx={{ width: '100%', mt: 2 }}>
                              <Grid container spacing={2}>
                                  {user && user.enterprise ? (
                                      <>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">Nom de l'entreprise</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.enterpriseName}</Typography>
                                          </Grid>

                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">Siege Social</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.siegeSocial}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">Code Postal</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.postalCode}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">Forme Juridique</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.formeJuridique}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">Registre de Commerce</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.registreCommerce}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">IBAN</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.iban}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="subtitle2">BIC</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{user.enterprise.bic}</Typography>
                                          </Grid>
                                      </>

                                  ):(
                                      <Typography variant="">nothing</Typography>

                                  )}

                              </Grid>
                          </Box>
                          <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOpenCompanyDrawer}>Modifier</Button>
                          <Drawer
                              anchor="right"
                              open={isCompanyDrawerOpen}
                              onClose={handleCloseCompanyDrawer}
                          >
                              <Box p={2} width='450px' textAlign='center'>
                                  <Typography variant='h6' component='div' sx={{ my: 3 }}>
                                      Mettre à jour les informations
                                  </Typography>
                                  {user && user.enterprise && (
                                      <EnterpriseUpdateForm
                                          enterpriseId={user.enterprise.id}
                                          initialData={user.enterprise}
                                          onSuccess={handleSuccessAndRefresh}
                                      />
                                  )}
                                  <Divider />
                              </Box>
                          </Drawer>

                      </Box>
                  </UserInfoCard>
              </Grid>
          </Grid>
      </>

    );
}
