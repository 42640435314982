import axios, {setAuthToken} from "../../axios";

export const createClient = async (userData) => {
    try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            throw new Error('No token found');
        }

        // No need to extract enterpriseId from token anymore as it is passed as a parameter

        setAuthToken(token);

        // If the token exists, make the request
        const response = await axios.post('/client/particular/create', userData);

        // Return the response data
        return response.data;

    } catch (error) {
        // More precise error handling
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}

export const getAllClient = async () => {

    try {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            throw new Error('No token found');
        }

        const response = await axios.get('/client/particular/getAllClients');

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);

        }

    }

}

export const getClientById = async (id) => {

    if (!id) {
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to getClientById.');
    }
    try{
        const token = localStorage.getItem('jwtToken');
        if(!token) {
            throw new Error('Token not Found');
        }

        setAuthToken(token);

        const response = await axios.get(`/client/particular/${id}`)
        return response.data;
    }catch(error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}

export const getAllClientFactures = async (id) => {

    if (!id) {
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to getAllClientFactures.');
    }
    try{
        const token = localStorage.getItem('jwtToken');
        if(!token) {
            throw new Error('Token not Found');
        }

        setAuthToken(token);

        const response = await axios.get(`/client/particular/getAllClientsFacture/${id}`)
        return response.data;
    }catch(error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}

export const UpdateClientInfo = async (id, userData) => {
    try{
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setAuthToken(token);
        } else {
            // Lancez une erreur si le token n'existe pas
            throw new Error('No token found');
        }

        // Si le token existe, effectuez la requête
        const response = await axios.put(`/client/particular/update/${id}`, userData);
        // Retournez les données de la réponse
        return response.data;
    }catch (error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }
}

export const deleteClientById = async (id) => {
    if(!id){
        console.error("Invalid ID passed:", id);
        throw new Error('Invalid ID passed to getQuestionnaireById.');
    }

    try {
        const token = localStorage.getItem('jwtToken');
        if(!token){
            throw new Error('Token not Found');
        }

        setAuthToken(token);

        const response = await axios.delete(`/client/particular/delete/${id}`)
        return response.data;
    }catch(error){
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            throw new Error(`Server error: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
            console.error('Request was made but no response was received:', error.request);
            throw new Error('Network error: Request was made but no response was received');
        } else {
            console.error('Error setting up the request:', error.message);
            throw new Error(`Setup error: ${error.message}`);
        }
    }

}
