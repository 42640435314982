import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Select,
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Iconify from '../components/iconify';

import Scrollbar from '../components/scrollbar';

import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import {
    downloadInvoice,
    getAllInvoice,
    statusEnvoyee,
    statusPayee,
    statusFacturee,
    deleteInvoiceById
} from "../api/routes/invoice";

import {useNavigate} from "react-router-dom";

import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {green, blue, orange, purple} from '@mui/material/colors';
import InvoiceStatusChip from '../components/invoice/InvoiceStatusChip'
import DeleteIcon from "@mui/icons-material/Delete";


const TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
    { id: 'N° Facture', label: 'N° Facture', alignRight: false },
    { id: 'Montant HT', label: 'Montant HT', alignRight: false },
    { id: 'Montant TTC', label: 'Montant TTC', alignRight: false },
    { id: 'Statut', label: 'Statut', alignRight: false },
    { id: 'Action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function InvoicePage() {

    const [getInvoice, setGetInvoice] = useState([]);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [plainPassword, setPlainPassword] = useState("");

    //const [getSessionClient, setGetSessionClient] = useState([]);

    //const [questionnaires, setQuestionnaires] = useState([]);

    const [currentClientId, setCurrentClientId] = useState(null);

    const navigate = useNavigate();

    /* useEffect(() => {
       const fetchAllData = async () => {
         try {
           // Fetch all session data
           const sessions = await getAllClient();

           // Fetch questionnaires for each session and associate them
           const sessionQuestionnaires = await Promise.all(sessions.map(async (session) => {
             try {
               // Fetch the questionnaire associated with the current session
               const questionnaireData = await getQuestionnaireById(session.questionnaireId);
               return { sessionId: session.id, questionnaire: questionnaireData };
             } catch (error) {
               console.error("Error fetching questionnaire for session ID:", session.id, error);
               return { sessionId: session.id, questionnaire: null };
             }
           }));

           // Create a map of session ID to its associated questionnaire
           const questionnaireMap = sessionQuestionnaires.reduce((acc, current) => {
             acc[current.sessionId] = current.questionnaire;
             return acc;
           }, {});

           setGetSession(sessions);
           setQuestionnaires(questionnaireMap);
         } catch (error) {
           setError("Error getting session data");
         }
       };
       fetchAllData();
     }, []);*/



    useEffect(()=> {
        const getAllInvoices = async ()=> {
            try{
                const data = await getAllInvoice();
                setGetInvoice(data);
            }catch (error){
                setError("Error getting all sessions")
            }
        };
        getAllInvoices();
    },[]);

    // Ajoutez un nouvel hook d'état pour gérer l'état du Drawer
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    // Créez une fonction pour ouvrir le Drawer
    //const handleOpenDrawer = () => setDrawerOpen(true);

    // Créez une fonction pour fermer le Drawer
    const handleCloseDrawer = () => setDrawerOpen(false);
    const handleOpenMenu = (event, invoiceId) => {
        setOpen(event.currentTarget);
        setCurrentClientId(invoiceId);  // Utilisation de setCurrentClientId
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = getInvoice ? getInvoice.map((n) => n.name) : [];
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangeStatus = async (invoiceId, newStatus) => {
        try {
            if (newStatus === "ENVOYEE") {
                await statusEnvoyee(invoiceId);
            } else if (newStatus === "PAYEE") {
                await statusPayee(invoiceId);
            }else if (newStatus === "FACTUREE") {
                await statusFacturee(invoiceId)
            }
            console.log(`Statut de la facture ${invoiceId} mis à jour en ${newStatus}`);
            // Mettre à jour l'affichage ou rafraîchir les données si nécessaire
            // ... Votre logique de mise à jour ici
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut:", error);
            // Gérer les erreurs ici
        }
    };

    const handleDownloadInvoice = async (invoiceId) => {
        try {
            const pdfBlob = await downloadInvoice(invoiceId);
            const downloadUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `facture_${invoiceId}.pdf`); // Nom du fichier à télécharger
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Erreur lors du téléchargement de la facture:', error);
        }
    };

    const handleViewInvoiceDetails = (invoiceId) => {
        // Navigate to the FactureDetailsPageView with the correct invoiceId
        navigate(`/FactureDetailsPageView/${invoiceId}`);
    };


    /* const handleClick = (event, name) => {
       const selectedIndex = selected.indexOf(name);
       let newSelected = [];
       if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
       } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
       } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
       } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
       }
       setSelected(newSelected);
     };*/

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (getInvoice?.length || 0)) : 0;


    const filteredSessions = applySortFilter(getInvoice || [], getComparator(order, orderBy), filterName);


    const isNotFound = !filteredSessions.length && !!filterName;

    const refreshSession = async () => {
        try{
            const data = await getAllInvoice();
            setGetInvoice(data);
            handleCloseDrawer();
        }catch (error) {
            setError("Error getting all sessions");
        }
    }

    const handleSuccessAndRefresh = async (plainPassword) => {
        console.log("Mot de passe saisi:", plainPassword);
        setPlainPassword(plainPassword);

        await refreshSession();
    }

    const handleDelete = async (invoiceId) => {

        if (window.confirm(`Êtes-vous sûr de vouloir supprimer la facture ${invoiceId}?`)) {
            try {
                await deleteInvoiceById(invoiceId);
                console.log("Attempting to delete invoice with ID:", invoiceId);
                const updatedInvoice = getInvoice.filter(invoice => invoice.id !== invoiceId);
                setGetInvoice(updatedInvoice);
                alert("Invoice successfully deleted.");
            } catch (error) {
                console.error("Error deleting the invoice:", error.message);
                alert("Une erreur s'est produite lors de la suppression de la facture. Veuillez réessayer.");
            }
        }
    };

    const handleCreateInvoice = () => {
       // const nextQuestionnaireNumber = Math.max(...questionnaires, 0) + 1;
        navigate("/CreateInvoicePage/");
    };

    const renderStatus = (status) => {
        switch (status) {
            case 'Payée':
                return <CheckCircleIcon style={{ color: green[500] }} />;
            case 'Facturée':
                return <CheckCircleIcon style={{ color: blue[500] }} />;
            // Ajoutez plus de cas au besoin
            default:
                return <CheckCircleIcon />;
        }
    };



    const getStatusStyle = (status) => {
        switch (status) {
            case 'PAYEE':
                return {
                    color: green[500],
                    backgroundColor: 'white',
                    fontWeight: 'bold',

                };
            case 'ENVOYEE':
                return {
                    color: orange[500],
                    backgroundColor: 'white', // Utilisez la bonne couleur pour orange de votre palette
                    fontWeight: 'bold',
                };
            case 'FACTUREE':
                return {
                    color: purple[500],
                    backgroundColor: 'white', // Utilisez la bonne couleur pour violet de votre palette
                    fontWeight: 'bold',
                };
            default:
                return {
                    color: 'black',
                    backgroundColor: 'none',
                    fontWeight: 'bold',
                };
        }
    };
    const updateStatus = async (invoiceId, newStatus) => {
        // Ici, vous pouvez appeler votre API pour mettre à jour le statut du invoice.
        await handleChangeStatus(invoiceId, newStatus);
        // Ensuite, mettez à jour l'état local du composant pour refléter le nouveau statut.
        const updatedInvoice = getInvoice.map(invoice => {
            if (invoice.id === invoiceId) {
                return { ...invoice, status: newStatus };
            }
            return invoice;
        });
        setGetInvoice(updatedInvoice);
    };
    const CustomSelect = styled(Select)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            // Augmentez les bordures pour tous les états
            '& fieldset': {
                borderColor: 'grey', // utilisez une couleur adaptée
                borderWidth: '1px', // ajustez la largeur de la bordure ici
            },
            // Survol de la bordure
            '&:hover fieldset': {
                borderColor: 'grey', // couleur au survol
            },
            // Bordure du champ de saisie lorsqu'il est en focus
            '&.Mui-focused fieldset': {
                borderColor: 'grey', // couleur au focus
            },
        },
    }));


    return (
        <>
            <Helmet>
                <title> Create Invoice | Factura </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Facture
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateInvoice}>
                        Nouvelle Facture
                    </Button>


                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={getInvoice ? getInvoice.length : 0}
                                    count={getInvoice ? getInvoice.length : 0}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody>
                                    {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, factureNumber,dateEmission, status,totalHT, totalTTC  } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;
                                        // const isItemSelected = selected.indexOf(id) !== -1;
                                        return (
                                            <StyledTableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                                                <StyledTableCell>

                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {dateEmission ? dateEmission : 'Loading...'}
                                                        </Typography>
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {factureNumber ? factureNumber : 'No email found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {totalHT ? totalHT : 'No iban found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {totalTTC ? totalTTC : 'No bic found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Stack direction="row" spacing={1}>
                                                        <CustomSelect
                                                            labelId={`status-label-${id}`}
                                                            id={`status-select-${id}`}
                                                            value={status || ''}
                                                            onChange={(e) => updateStatus(id, e.target.value)}
                                                            style={getStatusStyle(status)}
                                                        >
                                                            <MenuItem value="PAYEE">Payée</MenuItem>
                                                            <MenuItem value="ENVOYEE">Envoyée</MenuItem>
                                                            <MenuItem value="FACTUREE">Facturée</MenuItem>
                                                        </CustomSelect>
                                                    </Stack>
                                                </StyledTableCell>

                                                <StyledTableCell align="left">
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleOpenMenu(event, row.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <StyledTableRow style={{ height: 53 * emptyRows }}>
                                            <StyledTableCell colSpan={6} />
                                        </StyledTableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        component="div"
                        count={getInvoice ? getInvoice.length : 0}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />


                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >

                <MenuItem sx={{ color: 'error.main' }}
                          onClick={() => handleDelete(currentClientId)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
                <MenuItem
                    sx={{ color: 'success.main' }}
                    onClick={() => handleDownloadInvoice(currentClientId)}
                >
                    <Iconify icon={'eva:arrow-circle-down-outline'} sx={{ mr: 2 }} />
                    Download
                </MenuItem>
                <MenuItem sx={{ color: 'black' }}
                          onClick={() => handleViewInvoiceDetails(currentClientId)}
                >
                    <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
                    Voir
                </MenuItem>
            </Popover>


        </>
    );
}
