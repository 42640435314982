import React from 'react';
import { Container, Typography, Button, Link, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email'; // Importez l'icône d'email

export default function VerifyPage () {
    const resendEmail = () => {
        // Logique pour renvoyer l'email
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <EmailIcon color="primary" sx={{ fontSize: 60 }} />

                <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                    Verify Your Email Address
                </Typography>

                <Typography variant="body1" sx={{ mt: 2 }}>
                    Before proceeding, please check your email for a verification link.
                </Typography>
            </Box>
        </Container>
    );
};


