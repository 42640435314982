import axios from  'axios'

const instance = axios.create({
    baseURL: 'http://95.111.252.70:3090/v1/api',
});

export const setAuthToken = (token) => {
    if(token) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }else {
        delete instance.defaults.headers.common['Authorization']
    }
};

export default instance;