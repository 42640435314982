import React, {useState} from 'react';
import {Button, TextField, Stack, InputAdornment, IconButton} from "@mui/material";
import {createClient} from "../../api/routes/client";
import {Snackbar, Alert} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Iconify from "../../components/iconify";

export default function ClientForm({onSuccess}){
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        iban: "",
        bic: "",
        address: "",
        contact: "",
        town: "",
        note: "",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const[success, setSuccess] = useState(false);





    const handleChange =(e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null)
        setSuccess(false);
        if(onSuccess) {
            onSuccess()
        }

        try {
            const response = await createClient(formData);
            console.log(response)
            setLoading(false);
            setSuccess(true);
            if(onSuccess) {
                onSuccess();
            }


        }catch (error){
            setError(error)
            setLoading(false);
        }


    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        label="Nom"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required />
                    <TextField
                        label="Iban"
                        name="iban"
                        value={formData.iban}
                        onChange={handleChange}
                         />
                    <TextField
                        label="Bic"
                        name="bic"
                        value={formData.bic}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Adresse"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Ville"
                        name="town"
                        value={formData.town}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                    />



                    <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ position: 'relative' }}>
                        {loading ? <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', mt: '-12px', ml: '-12px' }} color="inherit" /> : "Créer un client"}
                    </Button>


                </Stack>
            </form>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}} open={success} autoHideDuration={16000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
                    Client crée avec Success
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}} open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error?.message || "Un problème lors de la création du client"}
                </Alert>
            </Snackbar>
        </>

    );


}