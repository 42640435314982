import { Navigate } from 'react-router-dom';

const TOKEN_EXPIRATION_MILLIS = 7200000; // 2 hours as set in backend app.properties

export default function PrivateRoute({ children }) {
    const token = localStorage.getItem('jwtToken');
    const tokenTimestamp = localStorage.getItem('jwtTokenTimestamp');
    const currentTime = new Date().getTime();

    if (!token || !tokenTimestamp || (currentTime - tokenTimestamp) >= TOKEN_EXPIRATION_MILLIS) {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtTokenTimestamp');
        return <Navigate to="/login" />;
    }

    return children;
}
