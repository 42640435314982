// component
import SvgColor from '../../../components/svg-color';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import {SettingsAccessibility} from "@mui/icons-material";
import {Setting2} from "iconsax-react";
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
 /* {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },*/
  {
    title: 'Clients',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
/*  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },*/
/*  {
    title: 'Blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },*/
  {
    title: 'Facture',
    path: '/dashboard/facture',
    icon: <PaymentIcon/>,
  },
  {
    title: 'Devis',
    path: '/dashboard/devis',
    icon: <ReceiptOutlinedIcon/>,
  },
  {
    title: 'Paramètre',
    path: '/dashboard/paramètre',
    icon: <Setting2/> ,
  },




 /* {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },*/
/*  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  },*/
];

export default navConfig;
