import React, {useEffect, useState} from 'react'
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';

import {getUserInfo} from "../../../api/routes/user";
import {setAuthToken} from "../../../api/axios";
import { useNavigate } from 'react-router-dom';
import {Skeleton} from "@mui/lab";


export default function AccountPopover() {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getUserInfo();
        setUser(data);
      } catch (error) {
        if (error.response && [401, 403].includes(error.response.status)) {
          // Déconnectez l'utilisateur et redirigez-le vers la page de connexion
          handleLogout();
        } else {
          setError("Erreur lors de la récupération des données de l'entreprise");
        }
      }
    };
    fetchUser();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
  };

  const handleParameters =  () => {
    navigate('/dashboard/paramètre');
  }


  const getInitial = (name) => {
    if (name && typeof name === 'string') {
      return name[0].toUpperCase();
    }
    return <Skeleton animation="wave" variant="circular"/>; // par défaut si le nom est absent ou invalide
  };

  return (
      <>
        <IconButton
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
        >
          <Avatar>{getInitial(user ? user.firstName : <Skeleton variant="circular"/>)}</Avatar>
        </IconButton>

        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 0,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {user ? user.firstName : 'Loading...'}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }} noWrap>
              {user ? user.email : 'Loading...'}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          {/*        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>*/}

          <MenuItem onClick={handleParameters} sx={{ m: 1 }}>
            Paramètres
          </MenuItem>
        {/*  <Divider sx={{ borderStyle: 'dashed' }} />*/}
          <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
            Logout
          </MenuItem>


        </Popover>
      </>
  );
}
