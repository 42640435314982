import axios, {setAuthToken} from "../../axios";


export const loginUser = async (userData) => {

    try {
        const response = await axios.post('/user/login', userData);

        const {accessToken} = response.data;
        if(accessToken) {
            localStorage.setItem('jwtToken', accessToken);
            setAuthToken(accessToken);
        }

        return response.data;
    }

    catch (error) {
        throw error;
    }
};