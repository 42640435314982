import React, {useState} from 'react';
import {Button, TextField, Stack, InputAdornment, IconButton} from "@mui/material";
import {Snackbar, Alert} from "@mui/material";
import { CircularProgress } from "@mui/material";
import {UpdateClientInfo} from "../../api/routes/client";

export default function ClientUpdateForm({clientId, initialData, onSuccess}){
    const [formData, setFormData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const[success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            console.log("formData :", formData);
            console.log("clientId :", clientId);
            const response = await UpdateClientInfo(clientId, formData);
            setLoading(false);
            setSuccess(true);
            if (onSuccess) {
                onSuccess(response);
            }
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {/* Fields for enterprise */}
                    <TextField
                        label="Nom du Client"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Iban"
                        name="iban"
                        value={formData.iban}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Bic"
                        name="bic"
                        value={formData.bic}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Adresse"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Ville"
                        name="town"
                        value={formData.town}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="A propos du client "
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        required
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{position: 'relative'}}
                    >
                        {loading && <CircularProgress size={24} sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            mt: '-12px',
                            ml: '-12px'
                        }}/>}
                        Mettre à jour
                    </Button>
                </Stack>
            </form>


            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Information mise à jour avec succès
                </Alert>
            </Snackbar>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error?.message || "Un problème est survenu lors de la mise à jour des informations"}
                </Alert>
            </Snackbar>
        </>

    );


}