import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Select,
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Iconify from '../components/iconify';

import Scrollbar from '../components/scrollbar';

import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import {
    downloadDevis,
    getAllDevis,
    statusEnregistre,
    statusRefuse,
    statusAnnule,
    statusAccepte,
    convertirDevisEnFacture,
    deleteDevisById
} from "../api/routes/devis";

import {useNavigate, useParams} from "react-router-dom";

import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import TransformIcon from '@mui/icons-material/Transform';
import PrintIcon from '@mui/icons-material/Print';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {green, blue, lightBlue, grey, purple, pink, red} from '@mui/material/colors';
import InvoiceStatusChip from '../components/invoice/InvoiceStatusChip'
import DeleteIcon from "@mui/icons-material/Delete";
import {EyeSlash} from "iconsax-react";
import {RemoveRedEye} from "@mui/icons-material";


const TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
    { id: 'N° Devis', label: 'N° Devis', alignRight: false },
    { id: 'Montant HT', label: 'Montant HT', alignRight: false },
    { id: 'Montant TTC', label: 'Montant TTC', alignRight: false },
    { id: 'Statut', label: 'Statut', alignRight: false },
    { id: 'Action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DevisPage() {

    const [getDevis, setGetDevis] = useState([]);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(null);
    const {devisId} = useParams()
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [currentClientId, setCurrentClientId] = useState(null);

    const navigate = useNavigate();


    useEffect(()=> {
        const getAll = async ()=> {
            try{
                const data = await getAllDevis();
                setGetDevis(data);
            }catch (error){
                setError("Error getting all devis")
            }
        };
        getAll();
    },[]);

    // Ajoutez un nouvel hook d'état pour gérer l'état du Drawer
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    // Créez une fonction pour ouvrir le Drawer
    //const handleOpenDrawer = () => setDrawerOpen(true);

    // Créez une fonction pour fermer le Drawer
    const handleCloseDrawer = () => setDrawerOpen(false);
    const handleOpenMenu = (event, devisId) => {
        setOpen(event.currentTarget);
        setCurrentClientId(devisId);  // Utilisation de setCurrentClientId
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = getDevis ? getDevis.map((n) => n.name) : [];
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangeStatus = async (devisId, newStatus) => {
        try {
            if (newStatus === "Accepte") {
                await statusAccepte(devisId);
            } else if (newStatus === "Enregistre") {
                await statusEnregistre(devisId);
            }
            else if (newStatus === "Annule") {
                await statusAnnule(devisId);
            }
            else if (newStatus === "refuse") {
                await statusRefuse(devisId);
            }
            console.log(`Statut du devis ${devisId} mis à jour en ${newStatus}`);
            // Mettre à jour l'affichage ou rafraîchir les données si nécessaire
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut:", error);
            // Gérer les erreurs ici
        }
    };

    const handleDownloadDevis = async (devisId) => {
        try {
            const pdfBlob = await downloadDevis(devisId);
            const downloadUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `devis_${devisId}.pdf`); // Nom du fichier à télécharger
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Erreur lors du téléchargement du devis:', error);
        }
    };

    const handleViewDevisDetails = (devisId) => {
        // Navigate to the DevisDetailsPage with the correct devisId
        navigate(`/DevisDetailsPageView/${devisId}`);
    };

    const handleTransformDevisEnFacture = async(devisId) => {
        try {
            await convertirDevisEnFacture(devisId);
            alert('Le devis a été converti en facture avec succès.');
            await refreshSession(); // pour rafraîchir la liste des devis
        } catch (error) {
            alert('Une erreur est survenue lors de la conversion du devis en facture: ' + error.message);
        }
    }

    const handleDelete = async (devisId) => {

        if (window.confirm(`Êtes-vous sûr de vouloir supprimer le devis ${devisId}?`)) {
            try {
                await deleteDevisById(devisId);
                console.log("Attempting to delete devis with ID:", devisId);
                const updatedDevis = getDevis.filter(devis => devis.id !== devisId);
                setGetDevis(updatedDevis);
                alert("Invoice successfully deleted.");
            } catch (error) {
                console.error("Error deleting the devis:", error.message);
                alert("Une erreur s'est produite lors de la suppression du devis. Veuillez réessayer.");
            }
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (getDevis?.length || 0)) : 0;


    const filteredSessions = applySortFilter(getDevis || [], getComparator(order, orderBy), filterName);


    const isNotFound = !filteredSessions.length && !!filterName;

    const refreshSession = async () => {
        try{
            const data = await getAllDevis();
            setGetDevis(data);
            handleCloseDrawer();
        }catch (error) {
            setError("Error getting all devis");
        }
    }




    const handleCreateDevis = () => {
        navigate("/CreateDevisPage/");
    };

    const renderStatus = (status) => {
        switch (status) {
            case 'Payée':
                return <CheckCircleIcon style={{ color: green[500] }} />;
            case 'Facturée':
                return <CheckCircleIcon style={{ color: blue[500] }} />;
            // Ajoutez plus de cas au besoin
            default:
                return <CheckCircleIcon />;
        }
    };

    // Cette fonction sera appelée chaque fois qu'un nouveau statut est sélectionné dans le menu déroulant.
    const updateStatus = async (devisId, newStatus) => {
        await handleChangeStatus(devisId, newStatus);
        const updatedDevis = getDevis.map(devis => {
            if (devis.id === devisId) {
                return { ...devis, status: newStatus };
            }
            return devis;
        });
        setGetDevis(updatedDevis);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Accepte':
                return {
                    color: green[500],
                    backgroundColor: 'white',
                    fontWeight: 'bold',
                };
            case 'Enregistre':
                return {
                    color: purple[500],
                    backgroundColor: 'white',
                    fontWeight: 'bold',
                };
            case 'Refuse':
                return {
                    color: pink[500],
                    backgroundColor: 'white',
                    fontWeight: 'bold',
                };
            case 'Annule':
                return {
                    color: red[500],
                    backgroundColor: 'white',
                    fontWeight: 'bold',
                };
            default:
                return {
                    color: 'black',
                    backgroundColor: 'none',
                    fontWeight: 'bold',
                };
        }
    };

    const CustomSelect = styled(Select)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {

            '& fieldset': {
                borderColor: 'grey',
                borderWidth: '1px',
            },

            '&:hover fieldset': {
                borderColor: 'grey',
            },

            '&.Mui-focused fieldset': {
                borderColor: 'grey',
            },
        },
    }));



    return (
        <>
            <Helmet>
                <title> Create Devis | Factura </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Devis
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateDevis}>
                        Nouveau Devis
                    </Button>


                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={getDevis ? getDevis.length : 0}
                                    count={getDevis ? getDevis.length : 0}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody>
                                    {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, devisNumber,dateEmission, status,totalHT, totalTTC  } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;
                                        //const isItemSelected = selected.indexOf(id) !== -1;
                                        return (
                                            <StyledTableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                                                <StyledTableCell>

                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {dateEmission ? dateEmission : 'Loading...'}
                                                        </Typography>
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {devisNumber ? devisNumber : 'No number found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {totalHT ? totalHT : 'No iban found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {totalTTC ? totalTTC : 'No bic found'}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Stack direction="row" spacing={1}>
                                                        <CustomSelect
                                                            labelId={`status-label-${id}`}
                                                            id={`status-select-${id}`}
                                                            value={status || ''}
                                                            onChange={(e) => updateStatus(id, e.target.value)}
                                                            style={getStatusStyle(status)}
                                                        >
                                                            <MenuItem value="Enregistre">Enregistré</MenuItem>
                                                            <MenuItem value="Accepte">Accepté</MenuItem>
                                                            <MenuItem value="Refuse">Refusé</MenuItem>
                                                            <MenuItem value="Annule">Annulé</MenuItem>
                                                        </CustomSelect>
                                                    </Stack>
                                                </StyledTableCell>

                                                <StyledTableCell align="left">
                                                   {/* <IconButton sx={{ color: 'primary.main' }} onClick={() => handleDownloadDevis(row.id)}>
                                                        <DownloadIcon />
                                                    </IconButton>*/}

             {/*                                       <IconButton sx={{ color: 'green' }} onClick={() => handleTransformDevisEnFacture(row.id)}>
                                                        <TransformIcon/>
                                                    </IconButton>*/}

                                                    {/*<IconButton sx={{ color: 'yellow' }} onClick={() => handleTransformDevisEnFacture(row.id)}>
                                                        <RemoveRedEye/>
                                                    </IconButton>*/}

                                                   {/* <IconButton sx={{ color: 'red' }} onClick={() => handleDelete(row.id)}>
                                                        <DeleteIcon/>
                                                    </IconButton>*/}


                                                  <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleOpenMenu(event, row.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <StyledTableRow style={{ height: 53 * emptyRows }}>
                                            <StyledTableCell colSpan={6} />
                                        </StyledTableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        component="div"
                        count={getDevis ? getDevis.length : 0}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />


                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >

                <MenuItem sx={{ color: 'error.main' }}
                onClick={() => handleDelete(currentClientId)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
                <MenuItem
                    sx={{ color: 'success.main' }}
                    onClick={() => handleDownloadDevis(currentClientId)}
                >
                    <Iconify icon={'eva:arrow-circle-down-outline'} sx={{ mr: 2 }} />
                    Download
                </MenuItem>
                <MenuItem sx={{ color: 'black' }}
                          onClick={() => handleViewDevisDetails(currentClientId)}
                >
                    <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
                    Voir
                </MenuItem>
                <MenuItem sx={{ color: 'purple' }}
                onClick={ () => handleTransformDevisEnFacture(currentClientId)}
                >
                    <Iconify icon={'eva:repeat-outline'} sx={{ mr: 2 }} />
                    Convertir
                </MenuItem>
            </Popover>


        </>
    );
}
