import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {

    Stack,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress, Snackbar, Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Iconify from "../../../components/iconify";
import {loginUser} from "../../../api/routes/login";


export default function LoginForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const[success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
        email:'',
        password:'',
    })
    const [loading, setLoading] = useState(false);
    const[error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try{
            const {accessToken} = await loginUser(formData);

            // When login is successful, set the token and the current timestamp in localStorage
            localStorage.setItem('jwtToken', accessToken);
            localStorage.setItem('jwtTokenTimestamp', new Date().getTime());

            // Navigate to the dashboard
            navigate("/dashboard/user", {replace: true});
            setSuccess(true);
        }catch (err) {
            setError("Erreur de connexion, veuillez réessayer");
            setSuccess(false);
        }finally {
            setLoading(false)
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        name="email"
                        label="Email address"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={formData.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{ my: 3 }}
                >
                    {loading ? <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', mt: '-12px', ml: '-12px' }} color="inherit" /> : "Je me connecte"}

                </LoadingButton>
            </form>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}} open={success} autoHideDuration={16000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
                    Login In
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}} open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error?.message || "Email ou Mot de passe incorrect"}
                </Alert>
            </Snackbar>
        </>


    );
}
